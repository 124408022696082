// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/ManropeBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/fonts/ManropeExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-display: swap;
    font-style: normal;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    font-family: "Manrope";
    font-weight: 700;
  }
  
  @font-face {
    font-display: swap;
    font-style: normal;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    font-family: "Manrope";
    font-weight: 200;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,4CAAyC;IACzC,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,4CAA+C;IAC/C,sBAAsB;IACtB,gBAAgB;EAClB","sourcesContent":["@font-face {\n    font-display: swap;\n    font-style: normal;\n    src: url(\"/public/fonts/ManropeBold.ttf\");\n    font-family: \"Manrope\";\n    font-weight: 700;\n  }\n  \n  @font-face {\n    font-display: swap;\n    font-style: normal;\n    src: url(\"/public/fonts/ManropeExtraLight.ttf\");\n    font-family: \"Manrope\";\n    font-weight: 200;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
