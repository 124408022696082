// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    padding: 0;
    font-family: Manrope;
  }
  
  * {
    box-sizing: border-box;
    line-height: normal;
    font-family: inherit;
    margin: unset;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,oBAAoB;EACtB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,oBAAoB;IACpB,aAAa;EACf","sourcesContent":["body {\n    margin: 0;\n    padding: 0;\n    font-family: Manrope;\n  }\n  \n  * {\n    box-sizing: border-box;\n    line-height: normal;\n    font-family: inherit;\n    margin: unset;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
